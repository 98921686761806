'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UploadStep = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactI18next = require('react-i18next');

var _antd = require('antd');

var _ = require('.');

var _RestartButton = require('./RestartButton');

var _RestartButton2 = _interopRequireDefault(_RestartButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Dragger = _antd.Upload.Dragger;

var UploadStep = exports.UploadStep = function (_React$Component) {
  _inherits(UploadStep, _React$Component);

  _createClass(UploadStep, null, [{
    key: 'displayErrorMessage',
    value: function displayErrorMessage(error) {
      _antd.notification.destroy();
      _antd.notification.error({
        message: 'Error',
        description: error,
        duration: 0
      });
    }
  }, {
    key: 'freshState',
    value: function freshState() {
      return {
        // UPLOAD_STEP state
        isUploading: false,
        uploadProgress: 0,
        fileKey: null,
        selectedFile: null,
        reset: false
      };
    }
  }]);

  function UploadStep() {
    _classCallCheck(this, UploadStep);

    var _this = _possibleConstructorReturn(this, (UploadStep.__proto__ || Object.getPrototypeOf(UploadStep)).call(this));

    _this.state = UploadStep.freshState();

    _this.beforeUpload = _this.beforeUpload.bind(_this);
    _this.onRemove = _this.onRemove.bind(_this);
    _this.uploadFile = _this.uploadFile.bind(_this);
    _this.resetState = _this.resetState.bind(_this);
    return _this;
  }

  _createClass(UploadStep, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      var batchStep = this.props.batchStep;

      var prevBatchStep = prevProps.batchStep;

      if (batchStep !== _.UPLOAD_STEP) {
        return;
      }

      if (batchStep !== prevBatchStep) {
        this.setState(UploadStep.freshState());
      }
    }
  }, {
    key: 'onRemove',
    value: function onRemove() {
      this.setState({ selectedFile: null });
    }
  }, {
    key: 'beforeUpload',
    value: function beforeUpload(file) {
      this.setState({ selectedFile: file });
      return false;
    }
  }, {
    key: 'resetState',
    value: function resetState() {
      var handleStepChange = this.props.handleStepChange;

      this.setState(UploadStep.freshState());

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      handleStepChange.apply(this, args);
    }
  }, {
    key: 'showResetButton',
    value: function showResetButton() {
      this.setState({ reset: true });
    }
  }, {
    key: 'uploadFile',
    value: async function uploadFile() {
      var _props = this.props,
          t = _props.t,
          uploadFileHandler = _props.uploadFileHandler,
          handleStepChange = _props.handleStepChange;
      var selectedFile = this.state.selectedFile;

      _antd.notification.destroy();

      this.setState({
        isUploading: true,
        uploadProgress: 100 // to keep it simple for now
      });

      handleStepChange(_.UPLOAD_STEP, {
        isUploading: true
      });

      try {
        var response = await uploadFileHandler(selectedFile);
        handleStepChange(_.VALIDATION_STEP, {
          uploadRequestResponse: response
        });
      } catch (error) {
        console.log(error);
        UploadStep.displayErrorMessage(t('common:unexpectedErrorMessage'));
        this.showResetButton();
        handleStepChange(_.UPLOAD_STEP, {
          isUploading: false
        });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _props2 = this.props,
          t = _props2.t,
          batchStep = _props2.batchStep;
      var _state = this.state,
          reset = _state.reset,
          selectedFile = _state.selectedFile,
          isUploading = _state.isUploading,
          uploadProgress = _state.uploadProgress;


      if (batchStep !== _.UPLOAD_STEP) {
        return null;
      }

      var fileList = [];

      if (selectedFile) {
        fileList.push(selectedFile);
      }

      return React.createElement(
        React.Fragment,
        null,
        !isUploading ? React.createElement(
          Dragger,
          {
            name: 'file',
            multiple: false,
            accept: '.xlsx',
            beforeUpload: this.beforeUpload,
            onChange: this.onChange,
            fileList: fileList,
            onRemove: this.onRemove
          },
          React.createElement(
            'p',
            { className: 'ant-upload-drag-icon' },
            React.createElement(_antd.Icon, { type: 'inbox' })
          ),
          !selectedFile ? React.createElement(
            'p',
            { className: 'ant-upload-text' },
            t('batchProcess:clickOrDrag')
          ) : React.createElement(
            'p',
            { className: 'ant-upload-text' },
            t('batchProcess:clickToChange')
          ),
          React.createElement(
            'p',
            { className: 'ant-upload-hint' },
            t('batchProcess:supportedFiles')
          )
        ) : React.createElement(_antd.Progress, { percent: uploadProgress, status: 'active', showInfo: false }),
        reset ? React.createElement(_RestartButton2.default, {
          handleStepChange: this.resetState
        }) : selectedFile && React.createElement(
          React.Fragment,
          null,
          React.createElement(_antd.Divider, { style: { margin: '10px 0 10px' } }),
          React.createElement(
            _antd.Button,
            {
              type: 'primary',
              onClick: this.uploadFile,
              disabled: isUploading,
              className: 'upload-button'
            },
            t('batchProcess:uploadButton')
          )
        )
      );
    }
  }]);

  return UploadStep;
}(React.Component);

UploadStep.propTypes = {
  t: _propTypes2.default.func.isRequired,
  batchStep: _propTypes2.default.any.isRequired,
  uploadFileHandler: _propTypes2.default.func.isRequired,
  handleStepChange: _propTypes2.default.func.isRequired
};

exports.default = (0, _reactI18next.withTranslation)(['batchProcess'])(UploadStep);