import React from 'react';
import PropTypes from 'prop-types';
import { TreeSelect } from 'antd';
import { withTranslation } from 'react-i18next';

const emptyTreeNode = (
  <TreeSelect.TreeNode />
);

export class RawTreeSelector extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inactiveCompanies: {},
      statusFilter: {},
      treeNodes: emptyTreeNode,
    };

    this.renderTreeNode = this.renderTreeNode.bind(this);
  }

  isEmptyStructure(structure) {
    if (!structure) {
      return true;
    }

    return Object.keys(structure).length === 0;
  }

  filterNodes(input, option) {
    return option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  renderTreeNode(node) {
    if (node === null) {
      return (<></>);
    }
    const { uuid, name, children, status } = node;
    const { onlyShowActive } = this.props;
    const childrenNode = [];

    if (onlyShowActive && status !== 'ACTIVATE') {
      return null;
    }

    for (let i = 0; i < children.length; i++) {
      if (onlyShowActive && children[i].status !== 'ACTIVATE') {
        continue;
      }
      childrenNode.push(this.renderTreeNode(children[i]));
    }

    return (
      <TreeSelect.TreeNode
        value={uuid}
        title={name}
        key={uuid}
      >
        {childrenNode}
      </TreeSelect.TreeNode>
    );
  }

  getPropForTreeSelect() {
    const {
      treeDefaultExpandAll,
      multiple,
      disabled,
      value,
      style,
      placeholder,
      onChange,
      className,
    } = this.props;

    const treeSelectProp = {
      className: className,
      allowClear: true,
      showSearch: true,
      filterTreeNode: this.filterNodes,
      treeDefaultExpandAll: treeDefaultExpandAll,
      dropdownStyle: { maxHeight: 400, overflow: 'auto' },
      onChange: onChange,
      disabled: disabled,
      multiple: multiple,
      labelInValue: true,
    };

    if (value !== undefined) {
      treeSelectProp['value'] = value;
    }

    if (placeholder !== undefined) {
      treeSelectProp['placeholder'] = placeholder;
    }

    if (placeholder !== style) {
      treeSelectProp['style'] = style;
    }
    return treeSelectProp;
  }

  render() {
    const { structureData } = this.props;

    const masterNode = this.renderTreeNode(structureData);
    const treeProps = this.getPropForTreeSelect();

    return (
      <span className="company-select">
        <TreeSelect
          {...treeProps}
        >
          { masterNode }
        </TreeSelect>
      </span>
    );
  }
}

RawTreeSelector.defaultProps = {
  structureData: null,
  onlyShowActive: false,
  treeDefaultExpandAll: false,
  multiple: false,
  disabled: false,
  value: undefined,
  style: undefined,
  placeholder: undefined,
};

RawTreeSelector.propTypes = {
  structureData: PropTypes.object,
  onlyShowActive: PropTypes.bool,
  treeDefaultExpandAll: PropTypes.bool,
  multiple: PropTypes.any,
  disabled: PropTypes.bool,

  value: PropTypes.any,
  ref: PropTypes.any,
  style: PropTypes.any,
  placeholder: PropTypes.any,

  onChange: PropTypes.func,
  className: PropTypes.string,
};

export const CompanyTreeSelector = withTranslation(['common'])(RawTreeSelector);