'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BatchTransactionView = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactI18next = require('react-i18next');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _humanizeDuration = require('humanize-duration');

var _humanizeDuration2 = _interopRequireDefault(_humanizeDuration);

var _antd = require('antd');

var _InfoCom = require('./InfoCom');

var _InfoCom2 = _interopRequireDefault(_InfoCom);

var _stylesModule = require('./styles.module.scss');

var _stylesModule2 = _interopRequireDefault(_stylesModule);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var BatchTransactionView = exports.BatchTransactionView = function (_React$Component) {
  _inherits(BatchTransactionView, _React$Component);

  _createClass(BatchTransactionView, null, [{
    key: 'getLocalTimeFormat',
    value: function getLocalTimeFormat(timestamp) {
      var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'LL LT';

      return _moment2.default.unix(timestamp).local().format(format);
    }
  }, {
    key: 'shortEnglishHumanizer',
    value: function shortEnglishHumanizer(timestamp) {
      return _humanizeDuration2.default.humanizer({
        language: 'shortEn',
        languages: {
          shortEn: {
            y: function y() {
              return 'y';
            },
            mo: function mo() {
              return 'mo';
            },
            w: function w() {
              return 'w';
            },
            d: function d() {
              return 'd';
            },
            h: function h() {
              return 'h';
            },
            m: function m() {
              return 'm';
            },
            s: function s() {
              return 's';
            },
            ms: function ms() {
              return 'ms';
            }
          }
        }
      })(timestamp);
    }
  }, {
    key: 'percentage',
    value: function percentage(x, y) {
      var divider = x / y;
      return divider * 100;
    }
  }]);

  function BatchTransactionView(props) {
    _classCallCheck(this, BatchTransactionView);

    var _this = _possibleConstructorReturn(this, (BatchTransactionView.__proto__ || Object.getPrototypeOf(BatchTransactionView)).call(this, props));

    _this.state = {
      successDownloadingStatus: false,
      failDownloadingStatus: false
    };
    _this.renderResultCount = _this.renderResultCount.bind(_this);
    _this.downloadLogFile = _this.downloadLogFile.bind(_this);
    return _this;
  }

  _createClass(BatchTransactionView, [{
    key: 'downloadLogFile',
    value: async function downloadLogFile(status) {
      var _props = this.props,
          t = _props.t,
          transaction = _props.transaction,
          _props$logFiles = _props.logFiles,
          success = _props$logFiles.success,
          fail = _props$logFiles.fail;


      var statusKey = status + 'DownloadingStatus';
      var updateState = {};
      updateState[statusKey] = true;

      this.setState(updateState);
      _antd.notification.destroy();

      var getLogUrl = status === 'success' ? success : fail;

      try {
        var fileUrl = null;
        if (typeof getLogUrl === 'string') {
          fileUrl = getLogUrl;
        } else if (typeof getLogUrl === 'function') {
          fileUrl = await getLogUrl(transaction.id);
        }
        window.location.replace(fileUrl);
      } catch (e) {
        _antd.notification.error({
          message: t('common:error'),
          description: t('common:unexpectedErrorMessage'),
          duration: 30
        });
      } finally {
        updateState[statusKey] = false;
        this.setState(updateState);
      }
    }
  }, {
    key: 'logDownloadButton',
    value: function logDownloadButton(status) {
      var _this2 = this;

      var t = this.props.t;
      var _state = this.state,
          successDownloadingStatus = _state.successDownloadingStatus,
          failDownloadingStatus = _state.failDownloadingStatus;


      var loadingStatus = status === 'success' ? successDownloadingStatus : failDownloadingStatus;

      return _react2.default.createElement(
        _antd.Tooltip,
        { placement: 'topLeft', title: t('logDownloadTooltip') },
        _react2.default.createElement(
          _antd.Button,
          {
            icon: 'download',
            loading: loadingStatus,
            disabled: loadingStatus,
            onClick: function onClick() {
              _this2.downloadLogFile(status);
            },
            className: 'log-download log-download-' + status
          },
          t('logButton')
        )
      );
    }
  }, {
    key: 'renderStatusSection',
    value: function renderStatusSection() {
      var t = this.props.t;

      var completed = this.transactionComplete;

      return _react2.default.createElement(
        _antd.Row,
        null,
        _react2.default.createElement(
          _antd.Col,
          { sm: 12, xs: 24 },
          _react2.default.createElement(_InfoCom2.default, {
            title: completed ? t('timeElapsed').toUpperCase() : t('status').toUpperCase(),
            value: completed ? this.timeElapsed : t('inProgress'),
            prefix: !completed ? _react2.default.createElement(_antd.Icon, { type: 'loading' }) : '',
            bordered: true
          })
        ),
        _react2.default.createElement(
          _antd.Col,
          { sm: 12, xs: 24 },
          _react2.default.createElement(
            'div',
            { className: _stylesModule2.default.headerInfo },
            _react2.default.createElement(_antd.Progress, {
              percent: this.calculatePercent,
              successPercent: this.calculatePercent,
              type: 'circle',
              width: 80,
              strokeWidth: 10
            })
          )
        )
      );
    }
  }, {
    key: 'renderResultCount',
    value: function renderResultCount(transaction, status) {
      var t = this.props.t;

      var completed = this.transactionComplete;

      return _react2.default.createElement(
        'div',
        { className: _stylesModule2.default.headerInfo },
        _react2.default.createElement(_InfoCom2.default, {
          title: t(status).toUpperCase(),
          value: transaction[status],
          prefix: completed && transaction[status] > 0 && this.logDownloadButton(status),
          bordered: true
        })
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var _props2 = this.props,
          t = _props2.t,
          loading = _props2.loading,
          error = _props2.error,
          transaction = _props2.transaction,
          logFiles = _props2.logFiles;


      return _react2.default.createElement(
        'div',
        { className: _stylesModule2.default.root },
        this.hasError && _react2.default.createElement(
          _react2.default.Fragment,
          null,
          _react2.default.createElement(_antd.Alert, {
            message: t('common:error'),
            description: error,
            type: 'error',
            showIcon: true
          })
        ),
        !this.hasError && _react2.default.createElement(
          _react2.default.Fragment,
          null,
          _react2.default.createElement(
            _antd.Card,
            { className: _stylesModule2.default.card, bordered: false, style: { marginBottom: '10px' }, loading: loading },
            !loading && _react2.default.createElement(
              _antd.Row,
              null,
              _react2.default.createElement(
                _antd.Col,
                { sm: 8, xs: 24 },
                _react2.default.createElement(_InfoCom2.default, { title: t('total').toUpperCase(), value: transaction.total, bordered: true })
              ),
              _react2.default.createElement(
                _antd.Col,
                { sm: 8, xs: 24 },
                this.renderResultCount(transaction, 'success', logFiles.success)
              ),
              _react2.default.createElement(
                _antd.Col,
                { sm: 8, xs: 24 },
                this.renderResultCount(transaction, 'fail', logFiles.fail)
              )
            )
          ),
          _react2.default.createElement(
            _antd.Card,
            { className: _stylesModule2.default.card, bordered: false, loading: loading, style: { marginBottom: '10px' } },
            !loading && this.renderStatusSection(transaction)
          ),
          _react2.default.createElement(
            _antd.Card,
            { className: _stylesModule2.default.card, bordered: false, loading: loading },
            !loading && _react2.default.createElement(
              _antd.Row,
              null,
              _react2.default.createElement(
                _antd.Col,
                { sm: 8, xs: 24 },
                _react2.default.createElement(_InfoCom2.default, { title: t('initiatedBy').toUpperCase(), value: transaction.initiatedBy, bordered: true })
              ),
              _react2.default.createElement(
                _antd.Col,
                { sm: 8, xs: 24 },
                _react2.default.createElement(_InfoCom2.default, { title: t('initiatedAt').toUpperCase(), value: BatchTransactionView.getLocalTimeFormat(transaction.createdAt, 'L LTS'), bordered: true })
              ),
              _react2.default.createElement(
                _antd.Col,
                { sm: 8, xs: 24 },
                _react2.default.createElement(_InfoCom2.default, { title: t('lastUpdatedAt').toUpperCase(), value: BatchTransactionView.getLocalTimeFormat(transaction.updatedAt, 'L LTS'), bordered: true })
              )
            )
          )
        )
      );
    }
  }, {
    key: 'transactionComplete',
    get: function get() {
      var transaction = this.props.transaction;

      return transaction.total <= transaction.success + transaction.fail;
    }
  }, {
    key: 'calculatePercent',
    get: function get() {
      var transaction = this.props.transaction;


      if (!transaction) {
        return 0;
      }

      return Math.round(BatchTransactionView.percentage(transaction.success + transaction.fail, transaction.total) * 10 / 10);
    }
  }, {
    key: 'calculateSuccessPercent',
    get: function get() {
      var transaction = this.props.transaction;


      if (!transaction) {
        return 0;
      }

      return Math.round(BatchTransactionView.percentage(transaction.success, transaction.total) * 10 / 10);
    }
  }, {
    key: 'timeElapsed',
    get: function get() {
      var transaction = this.props.transaction;

      var createdAt = _moment2.default.unix(transaction.createdAt);
      var updatedAt = _moment2.default.unix(transaction.updatedAt);

      return BatchTransactionView.shortEnglishHumanizer(createdAt.diff(updatedAt));
    }
  }, {
    key: 'hasError',
    get: function get() {
      var error = this.props.error;

      return error !== '' && error !== null && error !== undefined;
    }
  }]);

  return BatchTransactionView;
}(_react2.default.Component);

BatchTransactionView.defaultProps = {
  loading: false,
  error: null
};

BatchTransactionView.propTypes = {
  t: _propTypes2.default.func.isRequired,
  loading: _propTypes2.default.bool,
  error: _propTypes2.default.string,
  transaction: _propTypes2.default.shape({
    id: _propTypes2.default.string,
    total: _propTypes2.default.number,
    success: _propTypes2.default.number,
    fail: _propTypes2.default.number,
    initiatedBy: _propTypes2.default.string,
    createdAt: _propTypes2.default.number,
    updatedAt: _propTypes2.default.number
  }).isRequired,
  logFiles: _propTypes2.default.shape({
    success: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func]),
    fail: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.func])
  }).isRequired
};

exports.default = (0, _reactI18next.withTranslation)(['transaction'])(BatchTransactionView);