'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BatchProcess = exports.SUBMISSION_STEP = exports.VALIDATION_STEP = exports.UPLOAD_STEP = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _antd = require('antd');

var _reactI18next = require('react-i18next');

var _styles = require('./styles.scss');

var _styles2 = _interopRequireDefault(_styles);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Step = _antd.Steps.Step;
var UPLOAD_STEP = exports.UPLOAD_STEP = 0;
var VALIDATION_STEP = exports.VALIDATION_STEP = 1;
var SUBMISSION_STEP = exports.SUBMISSION_STEP = 2;

var BatchProcess = exports.BatchProcess = function (_Component) {
  _inherits(BatchProcess, _Component);

  function BatchProcess() {
    _classCallCheck(this, BatchProcess);

    var _this = _possibleConstructorReturn(this, (BatchProcess.__proto__ || Object.getPrototypeOf(BatchProcess)).call(this));

    _this.state = {
      batchStep: UPLOAD_STEP,
      batchState: {}
    };

    _this.handleStepChange = _this.handleStepChange.bind(_this);
    return _this;
  }

  _createClass(BatchProcess, [{
    key: 'handleStepChange',
    value: function handleStepChange(newBatchStep, updatedBatchState) {
      // Only update if either batchStep or batchState is updated
      var _state = this.state,
          batchStep = _state.batchStep,
          batchState = _state.batchState;


      var newBatchState = updatedBatchState || {};

      if (batchStep !== newBatchStep || batchState !== newBatchState) {
        this.setState({
          batchStep: newBatchStep,
          batchState: newBatchState
        });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          t = _props.t,
          children = _props.children;
      var _state2 = this.state,
          batchStep = _state2.batchStep,
          batchState = _state2.batchState;


      var propsChildren = [];
      if (Array.isArray(children)) {
        propsChildren = children;
      } else {
        propsChildren.push(children);
      }

      var renderPropsChildren = _react2.default.Children.map(propsChildren, function (child) {
        return _react2.default.cloneElement(child, {
          batchStep: batchStep,
          batchState: batchState,
          handleStepChange: _this2.handleStepChange
        });
      });

      return _react2.default.createElement(
        _antd.Row,
        { gutter: 24 },
        _react2.default.createElement(
          _antd.Col,
          { xl: 24, lg: 24, md: 24, sm: 24, xs: 24 },
          _react2.default.createElement(
            _antd.Card,
            { bordered: false },
            _react2.default.createElement(
              _antd.Steps,
              { current: batchStep },
              _react2.default.createElement(Step, {
                title: t('batchProcess:uploadStep'),
                icon: _react2.default.createElement(_antd.Icon, {
                  type: batchStep === UPLOAD_STEP && batchState.isUploading ? 'loading' : 'inbox'
                })
              }),
              _react2.default.createElement(Step, { title: t('batchProcess:validateStep'), icon: _react2.default.createElement(_antd.Icon, { type: 'solution' }) }),
              _react2.default.createElement(Step, { title: t('batchProcess:submitStep'), icon: _react2.default.createElement(_antd.Icon, { type: 'smile-o' }) })
            ),
            _react2.default.createElement(
              'div',
              { className: _styles2.default.stepContent },
              renderPropsChildren
            )
          )
        )
      );
    }
  }]);

  return BatchProcess;
}(_react.Component);

BatchProcess.propTypes = {
  t: _propTypes2.default.func.isRequired,
  children: _propTypes2.default.any
};

BatchProcess.defaultProps = {
  children: []
};

exports.default = (0, _reactI18next.withTranslation)(['batchProcess'])(BatchProcess);