'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RestartButton = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _antd = require('antd');

var _reactI18next = require('react-i18next');

var _ = require('.');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var RestartButton = exports.RestartButton = function (_React$Component) {
  _inherits(RestartButton, _React$Component);

  function RestartButton() {
    _classCallCheck(this, RestartButton);

    var _this = _possibleConstructorReturn(this, (RestartButton.__proto__ || Object.getPrototypeOf(RestartButton)).call(this));

    _this.onButtonClick = _this.onButtonClick.bind(_this);
    return _this;
  }

  _createClass(RestartButton, [{
    key: 'onButtonClick',
    value: function onButtonClick() {
      var handleStepChange = this.props.handleStepChange;

      _antd.notification.destroy();
      handleStepChange(_.UPLOAD_STEP, {});
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          t = _props.t,
          showDivider = _props.showDivider,
          buttonType = _props.buttonType;


      return React.createElement(
        React.Fragment,
        null,
        showDivider && React.createElement(_antd.Divider, { style: { margin: '10px 0 10px' } }),
        React.createElement(
          _antd.Button,
          {
            type: buttonType,
            onClick: this.onButtonClick
          },
          t('batchProcess:tryAgain')
        )
      );
    }
  }]);

  return RestartButton;
}(React.Component);

RestartButton.defaultProps = {
  showDivider: true,
  buttonType: 'primary'
};

RestartButton.propTypes = {
  t: _propTypes2.default.func.isRequired,
  showDivider: _propTypes2.default.bool,
  buttonType: _propTypes2.default.string,
  handleStepChange: _propTypes2.default.func.isRequired
};

exports.default = (0, _reactI18next.withTranslation)(['batchProcess'])(RestartButton);