import React from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Col, Row, Divider, Radio, Icon, Button, Dropdown, Menu, Spin
} from 'antd';

import PhysicalLocationList from '../../Containers/PhysicalLocationList';
import PhysicalLocationStructure from '../../Containers/PhysicalLocationStructure';
import PhysicalLocationFilter from '../PhysicalLocationFilter';
import PhysicalLocationExporter from '../../Containers/PhysicalLocationExporter';
import Base from '../Base';

export class PhysicalLocations extends Base {
  constructor(props) {
    super(props);

    this.state = {
      view: 'list',
      structureFilter: {},
      flatFilter: {},
      sort: null,
      loadingCompanyStructure: true,
      failedToloadRequiredData: false,
    };

    this.onViewChange = this.onViewChange.bind(this);
    this.onStructureFilter = this.onStructureFilter.bind(this);
    this.onFlatFilter = this.onFlatFilter.bind(this);
    this.onSortingChange = this.onSortingChange.bind(this);
  }

  componentDidMount() {
    this.getCompanyStructure();
  }

  async getCompanyStructure() {
    const { getCompanyStructure, t } = this.props;
    try {
      await super.dispatchWithAuth(getCompanyStructure);
      this.setState({ loadingCompanyStructure: false });
    } catch (error) {
      console.log(error);
      if (error.status !== 401) {
        super.handleError(t('failedToFetchCompanyData'));
        this.setState({
          loadingCompanyStructure: false,
          failedToloadRequiredData: true
        });
      }
    }
  }

  onViewChange(e) {
    this.setState({
      view: e.target.value,
      structureFilter: {},
      flatFilter: {},
    });
  }

  onFlatFilter(filter) {
    this.setState({
      flatFilter: filter
    });
  }

  onStructureFilter(filter) {
    this.setState({
      structureFilter: filter
    });
  }

  onSortingChange(sort) {
    this.setState({ sort });
  }

  renderView() {
    const { view, structureFilter, flatFilter } = this.state;
    if (view === 'list') {
      return (
        <div className="physical-location-list">
          <PhysicalLocationList
            filter={flatFilter}
            onSortingChange={this.onSortingChange}
          />
        </div>
      );
    }

    return (
      <div className="physical-location-structure">
        <PhysicalLocationStructure
          filter={structureFilter}
        />
      </div>
    );
  }

  renderButtons(isMobile) {
    const { t } = this.props;
    const { flatFilter, sort, view } = this.state;

    return (
      <Button.Group
        style={isMobile ? { marginTop: 30 } : { float: 'right' }}
      >
        <PhysicalLocationExporter
          filter={flatFilter}
          sort={sort}
          text={t('export')}
          style={isMobile ? { width: '100%', marginBottom: 15 } : {}}
          disabledButton={view === 'tree'}
        />
        <Dropdown
          trigger={['click']}
          overlay={this.renderBatchProcessMenu()}
        >
          <Button
            icon="retweet"
            style={isMobile ? { width: '100%', marginBottom: 15 } : {}}
          >
            {t('batchProcess')}
          </Button>
        </Dropdown>
      </Button.Group>
    );
  }

  renderBatchProcessMenu() {
    const { t } = this.props;
    return (
      <Menu>
        <Menu.Item key="new-batch-transaction">
          <Link to="/physical-location/batch-process">
            <Icon type="upload" />
            {' '}
            {t('newBatchProcess')}
          </Link>
        </Menu.Item>
        {/* <Menu.Item key="view-batch-transaction">
          <Link to="/physical-location/batch-transaction">
            <Icon type="bars" /> {t('transactions')}
          </Link>
        </Menu.Item> */}
      </Menu>
    );
  }

  render() {
    const { t } = this.props;
    const {
      view,
      loadingCompanyStructure,
      failedToloadRequiredData
    } = this.state;

    if (loadingCompanyStructure || failedToloadRequiredData) {
      return <></>;
    }

    return (
      <Media query="(max-width: 769px)">
        { (isMobile) => (
          <>
            <Row type="flex" justify="center" align="top">
              <Col span={12}>
                <h1><strong>{t('physicalLocations')}</strong></h1>
                <Divider />
              </Col>

              <Col span={12} className="text-right">
                <Radio.Group
                  value={view}
                  buttonStyle="solid"
                  onChange={this.onViewChange}
                >
                  <Radio.Button value="list"><Icon type="bars" /></Radio.Button>
                  <Radio.Button value="tree"><Icon type="apartment" /></Radio.Button>
                </Radio.Group>
                <Divider style={{ marginTop: 16 }} />
              </Col>

            </Row>

            <Row style={{ marginBottom: 20 }}>
              <Col span={24}>
                <Spin
                  spinning={loadingCompanyStructure}
                  size="large"
                  style={{ marginTop: 40 }}
                >
                  {view === 'tree' ? (
                    <PhysicalLocationFilter
                      onFilter={this.onStructureFilter}
                      structureFilter={true}
                      key={'treeFilter'}
                    />
                  ) : (
                    <PhysicalLocationFilter
                      onFilter={this.onFlatFilter}
                      structureFilter={false}
                      key={'flatFilter'}
                    />
                  )}
                </Spin>
              </Col>
            </Row>

            <Row>
              {this.renderButtons(isMobile)}
            </Row>

            <Row>
              <Col span={24}>
                { this.renderView() }
              </Col>
            </Row>

          </>
        )}
      </Media>
    );
  }
}

PhysicalLocations.propTypes = {
  t: PropTypes.func.isRequired,
  getCompanyStructure: PropTypes.func.isRequired,
};

export default withTranslation(['physicalLocations', 'common'])(PhysicalLocations);
