import React from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

import PhysicalLocations from './Containers/PhysicalLocations';
import BatchPhysicalLocation from './Containers/BatchPhysicalLocation';
import PhysicalLocationTransactionView from './Containers/PhysicalLocationTransactionView';

/*
  For documentation on deeply nested routes refer here:
  https://github.com/reacttraining/react-router/tree/master/packages/react-router-config
*/

const Root = ({ route }) => (
  <div>
    {/* child routes won't render without this */}
    {renderRoutes(route.routes)}
  </div>
);

Root.propTypes = {
  route: PropTypes.object.isRequired
};

export const routes = [{
  component: Root,
  routes: [
    {
      path: '/physical-location',
      exact: true,
      component: PhysicalLocations
    },
    {
      path: '/physical-location/batch-process',
      exact: true,
      component: BatchPhysicalLocation,
    },
    {
      path: '/physical-location/transaction/:uuid',
      exact: true,
      component: PhysicalLocationTransactionView,
    }
  ]
}];
