import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button } from 'antd';

import Base from '../Base';

function download(url) {
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export class PhysicalLocationExporter extends Base {
  constructor(props) {
    super(props);

    this.state = {};
    this.exportToExcel = this.exportToExcel.bind(this);
    this.download = download.bind(this);
    this.exportToExcel = this.exportToExcel.bind(this);
    this.getTransactionStatus = this.getTransactionStatus.bind(this);
  }

  async exportToExcel() {
    const {
      t, exportInit, filter, sort
    } = this.props;
    try {
      this.loading(true);
      this.clearNotification();
      this.showMessage(t('exportPhysicalLocationInfo'));
      const response = await super.dispatchWithAuth(exportInit, filter, sort);
      this.getTransactionStatus(response.data.transactionId);
    } catch (err) {
      if (err.status !== 401) {
        super.handleError(t('fetchError'));
      } else {
        this.loading(false);
        this.hideMessage(false);
      }
    }
  }

  async getTransactionStatus(transactionId) {
    const { t, getExportTransactionStatus } = this.props;
    try {
      const response = await super.dispatchWithAuth(getExportTransactionStatus, transactionId);
      if (response.data.attributes.status === 'FINISHED') {
        this.download(response.data.attributes.extraDetail.downloadUrl);
        this.loading(false);
        this.hideMessage(false);
      } else if (response.data.attributes.status === 'UNEXPECTED_ERROR') {
        super.handleError(t('fetchError'));
        this.loading(false);
      } else {
        setTimeout(() => {
          this.getTransactionStatus(transactionId);
        }, 500);
      }
    } catch (err) {
      if (err.status !== 401) {
        super.handleError(t('fetchError'));
      } else {
        this.loading(false);
        this.hideMessage(false);
      }
    }
  }

  loading(state) {
    this.setState({ loading: state });
  }

  render() {
    const { style, disabledButton, text } = this.props;
    const { loading } = this.state;
    return (
      <>
        <Button
          icon="download"
          loading={loading}
          onClick={this.exportToExcel}
          style={style}
          disabled={disabledButton}
        >
          {text}
        </Button>
      </>
    );
  }
}

PhysicalLocationExporter.defaultProps = {
  filter: {},
  sort: {},
  disabledButton: false
};

PhysicalLocationExporter.propTypes = {
  t: PropTypes.func.isRequired,
  exportPhysicalLocationListToExcel: PropTypes.func.isRequired,
  getIdToken: PropTypes.func.isRequired,
  filter: PropTypes.object,
  sort: PropTypes.object,
  disabledButton: PropTypes.bool
};

export default withTranslation(['physicalLocations', 'common'])(PhysicalLocationExporter);
