import React from 'react';
import PropTypes from 'prop-types';
import equal from 'deep-equal';
import { withTranslation } from 'react-i18next';
import {
  Row, Tree, Spin, Card, Empty, Button, Popover, Icon
} from 'antd';

import Base from '../Base';
import { inlineLoader } from '../../sass/modules/loader.module.scss';

export class PhysicalLocationStructure extends Base {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      structures: [],
      page: 1,
      pageSize: 30,
      hasMoreSite: false
    };

    this.loading = this.loading.bind(this);
    this.loadMore = this.loadMore.bind(this);
  }

  componentDidMount() {
    this.fetchStructure();
  }

  resetPagination(then) {
    this.setState({
      structures: [],
      pageNumber: 1,
      pageSize: 30,
      hasMoreSite: false
    }, then);
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props;
    if (!equal(prevProps.filter, filter)) {
      this.resetPagination(this.fetchStructure);
    }
  }

  async fetchStructure() {
    const { t, getPhysicalLocationStructure, filter } = this.props;
    if (!filter['filter.companies']) {
      return;
    }

    const { pageNumber, pageSize, structures } = this.state;

    const query = {
      'filter.depthLevel': filter['filter.depthLevel'],
      'filter.includeSubLevelCompanies': filter['filter.includeSubLevelCompanies'],
      'page.number': pageNumber,
      'page.size': pageSize
    }

    if (filter['filter.siteName']) {
      query['filter.siteName'] = filter['filter.siteName'];
    }

    try {
      this.loading(true);
      const payload = await super.dispatchWithAuth(
        getPhysicalLocationStructure,
        filter['filter.companies'],
        query
      );
      const { meta, data } = payload;
      const updatedState = {
        hasMoreSite: pageNumber < meta['site.pagination'].pageCount ? true : false,
        structures: structures.concat(data)
      };
      this.setState(updatedState);
    } catch (err) {
      console.log(err);
      // Unauthorized errors are already handled in the reducer
      if (err.status !== 401) {
        super.handleError(t('fetchError'));
      }
    } finally {
      this.loading(false);
    }
  }

  loadMore() {
    const { pageNumber } = this.state;
    this.setState({pageNumber: pageNumber + 1}, this.fetchStructure)
  }

  extractCompanyName(node) {
    if (!node.companies) {
      return (
        <span>&nbsp;</span>
      );
    }
    const companyNames = [];
    node.companies.forEach((comUuuid) => {
      companyNames.push(node.metadata.companyData[comUuuid].name);
    });
    return companyNames.join(', ');
  }

  renderNodeDetail(node) {
    return (
      <>
        <p><strong>Companies: </strong> {this.extractCompanyName(node)}</p>
        <p><strong>UUID: </strong> {node.uuid}</p>
      </>
    )
  }

  renderTreeNodes(children, level) {
    if (!children) {
      return (<></>);
    }

    const { TreeNode } = Tree;

    return children.map((physicalLocation) => (
      <TreeNode
        selectable={false}
        title={
          <Popover
            content={this.renderNodeDetail(physicalLocation)}
            title={`${physicalLocation.name} - Level ${level}`}
          >
            {physicalLocation.name}&nbsp;
            {physicalLocation.companies.length > 1 && (
              <Icon type="alert" theme="twoTone" />
            )}
          </Popover>
        }
        key={physicalLocation.uuid}
      >
        {this.renderTreeNodes(physicalLocation.children, level + 1)}
      </TreeNode>
    ));
  }

  loading(state) {
    this.setState({ loading: state });
  }

  render() {
    const { t, filter } = this.props;
    const { loading, structures, pageNumber, hasMoreSite } = this.state;

    if (!filter['filter.companies']) {
      return (<Empty description={t('structureSelectCompanyToStart')}/>);
    }

    if (loading && pageNumber === 1) {
      return (
        <Spin
          size="large"
          style={{ marginTop: 20 }}
          className={inlineLoader}
        />
      );
    }

    if (!loading && structures.length === 0) {
      return (
        <Empty message={t('common.noRecordsFound')} />
      );
    }

    return (
      <Row style={{marginTop: '10px'}}>
        <Card>
          <Tree
            defaultExpandAll
            autoExpandParent
          >
            {this.renderTreeNodes(structures, 0)}
          </Tree>
          {loading && (
            <Spin
              size="large"
              className={inlineLoader}
            />
          )}
          {!loading && hasMoreSite && (
            <Button
              type="link"
              onClick={this.loadMore}
            >
              {t('structureLoadMore')}
            </Button>
          )}
        </Card>
      </Row>
    );
  }
}

PhysicalLocationStructure.defaultProps = {
};

PhysicalLocationStructure.propTypes = {
  t: PropTypes.func.isRequired,
  getPhysicalLocationStructure: PropTypes.func.isRequired,
  getIdToken: PropTypes.func.isRequired,
  filter: PropTypes.object
};

export default withTranslation(['physicalLocations'])(PhysicalLocationStructure);
