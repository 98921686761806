export async function api(token, params) {
  const {
    endpoint,
    method,
    query = null,
    body = null,
    contentType = 'application/json'
  } = params;

  const fetchConfig = {
    method,
    headers: {
      'content-type': contentType,
      authorization: token
    }
  };

  if (body) {
    if (contentType === 'application/json') {
      fetchConfig.body = JSON.stringify(body);
    } else {
      fetchConfig.body = body;
    }
  }

  let fetchUrl = endpoint;
  if (query) {
    fetchUrl += query;
  }

  const result = await fetch(
    fetchUrl,
    fetchConfig
  );

  const payload = await result.json();

  if (!result.ok) {
    throw Object.assign(new Error(), {
      status: result.status,
      message: payload
    });
  }

  return payload;
}
