const config = {
  apiGateway: {
    REGION: 'us-west-2',
    PHY_LOCATION_APIGATEWAY_URL: 'https://j2kvbl1qd4.execute-api.us-west-2.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://ai02sj4ye9.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://c31jda7uba.execute-api.us-west-2.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: '1.0.0',
    HOSTNAME: 'https://taxonomyphysicallocation.dev.gromit.forwoodsafety.com/'
  }
};

export default config;
