'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ResponsiveTable = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

require('./styles.scss');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ResponsiveTable = exports.ResponsiveTable = function (_Component) {
  _inherits(ResponsiveTable, _Component);

  function ResponsiveTable() {
    _classCallCheck(this, ResponsiveTable);

    return _possibleConstructorReturn(this, (ResponsiveTable.__proto__ || Object.getPrototypeOf(ResponsiveTable)).apply(this, arguments));
  }

  _createClass(ResponsiveTable, [{
    key: 'render',
    value: function render() {
      return _react2.default.createElement(
        'table',
        { className: 'responsive-table' },
        this.header,
        this.body
      );
    }
  }, {
    key: 'header',
    get: function get() {
      var header = this.props.header;


      return _react2.default.createElement(
        'thead',
        { className: 'ant-table-thead' },
        _react2.default.createElement(
          'tr',
          null,
          header.map(function (_ref) {
            var key = _ref.key,
                label = _ref.label,
                sortFn = _ref.sortFn,
                isCurrentSortField = _ref.isCurrentSortField,
                isReverse = _ref.isReverse,
                width = _ref.width;
            return _react2.default.createElement(
              'th',
              {
                key: key,
                scope: 'col',
                width: width,
                className: (0, _classnames2.default)({
                  sortable: sortFn,
                  sort: isCurrentSortField,
                  'sort-down': isCurrentSortField && isReverse,
                  'sort-up': isCurrentSortField && !isReverse
                }),
                onClick: function onClick() {
                  return sortFn && sortFn();
                }
              },
              label,
              _react2.default.createElement('span', { className: 'up' }),
              _react2.default.createElement('span', { className: 'down' })
            );
          })
        )
      );
    }
  }, {
    key: 'body',
    get: function get() {
      var _props = this.props,
          header = _props.header,
          data = _props.data;


      return _react2.default.createElement(
        'tbody',
        { className: 'ant-table-tbody' },
        data.map(function (item) {
          return _react2.default.createElement(
            'tr',
            { key: item.uuid, className: 'ant-table-row' },
            header.map(function (_ref2) {
              var label = _ref2.label,
                  key = _ref2.key;
              return _react2.default.createElement(
                'td',
                { key: item.uuid + '-' + key, 'data-title': label },
                // Supports dot notation strings as key object references
                key.split('.').reduce(function (o, i) {
                  return o[i];
                }, item)
              );
            })
          );
        })
      );
    }
  }]);

  return ResponsiveTable;
}(_react.Component);

ResponsiveTable.propTypes = {
  header: _propTypes2.default.array.isRequired,
  data: _propTypes2.default.array.isRequired
};

exports.default = ResponsiveTable;