import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Media from 'react-media';
import {
  Col, Row, Input, Button, Card, Form, Checkbox, Select
} from 'antd';
import { CompanyTreeSelectorWithData } from '../../Containers/CompanySelector';

export class PhysicalLocationFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'filter': this.defaultFilter()
    }

    this.searchInput = React.createRef();
    this.handleCompanySelectChange = this.handleCompanySelectChange.bind(this);
    this.handleNameInputChange = this.handleNameInputChange.bind(this);
    this.resetAllFilter = this.resetAllFilter.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.handleSubLevelInputChange = this.handleSubLevelInputChange.bind(this);
    this.handleSiteNameInputChange = this.handleSiteNameInputChange.bind(this);
    this.handleDepthLevelInputChange = this.handleDepthLevelInputChange.bind(this);
  }

  defaultFilter() {
    const { structureFilter } = this.props;
    if (structureFilter) {
      return {
        'filter.includeSubLevelCompanies': true,
        'filter.depthLevel': 5
      };
    }

    return {
      'filter.includeSubLevelCompanies': true
    };
  }

  applyFilter(key, value, clearOnEmpty = false) {
    if (!key) {
      return;
    }

    const { filter } = this.state;
    const newFilterValue = {...filter};

    if (clearOnEmpty && !value) {
      return this.clearFilter(key);
    }

    if (typeof value === 'string') {
      value = value.trim();
    }

    newFilterValue[`filter.${key}`] = value;
    this.setState({
      filter: newFilterValue
    });
  }

  clearFilter(key) {
    const { filter } = this.state;
    const filterKey = `filter.${key}`
    if (filterKey in filter) {
      const newFilterValue = {...filter};
      delete newFilterValue[filterKey];
      this.setState({
        filter: newFilterValue
      });
    }
  }

  handleCompanySelectChange(selectedNode) {
    if (!selectedNode) {
      this.clearFilter('companies');
      return;
    }
    this.applyFilter('companies', selectedNode.value);
  }

  handleNameInputChange(e) {
    this.applyFilter('name', e.target.value, true);
  }

  handleSiteNameInputChange(e) {
    this.applyFilter('siteName', e.target.value, true);
  }

  handleSubLevelInputChange(e) {
    this.applyFilter('includeSubLevelCompanies', e.target.checked, true);
  }

  handleDepthLevelInputChange(value) {
    this.applyFilter('depthLevel', value, true);
  }

  resetAllFilter() {
    const { onFilter, form } = this.props;
    const defaultFilter = this.defaultFilter();
    form.resetFields();
    this.setState(defaultFilter);
    onFilter(defaultFilter);
  }

  onFormSubmit(e) {
    e.preventDefault();
    const { onFilter } = this.props;
    const { filter } = this.state;
    onFilter(filter);
  }

  buildDepthLevelSelector(min, max) {
    const levelOptions = [];
    for (let i = min; i <= max; i++) {
      levelOptions.push((<Select.Option key={i}>{i}</Select.Option>));
    }
    return (
      <Select onChange={this.handleDepthLevelInputChange}>
        {levelOptions}
      </Select>
    );
  }

  render() {
    const { t, renderButtons, onFilter, structureFilter } = this.props;
    const { getFieldDecorator } = this.props.form;

    const { filter } = this.state;

    return (
      <Media query="(max-width: 769px)">
        {(isMobile) => (
          <Card
            title={t('filterForm:title')}
            extra={renderButtons && renderButtons()}
            actions={[
              <>
                <Button
                  icon="search"
                  style={{
                    'float': 'right',
                    'marginRight': '10px',
                  }}
                  type="primary"
                  onClick={() => onFilter(filter)}
                >
                  Search
                </Button>
                <Button
                  icon="redo"
                  style={{
                    'float': 'right',
                    'marginRight': '10px',
                  }}
                  onClick={this.resetAllFilter}
                >
                  Reset
                </Button>
              </>
            ]}
          >
            <Form onSubmit={this.onFormSubmit}>
              <Row gutter={[10, 10]}>
                <Col md={12} xs={24} className="search">
                    <Form.Item
                      label={t('filterForm:companyStructure.label')}
                      style={{marginBottom: '0px'}}
                    >
                      {getFieldDecorator('filter.companies')(
                        <CompanyTreeSelectorWithData
                          treeDefaultExpandAll={true}
                          onChange={this.handleCompanySelectChange}
                          placeholder={
                            structureFilter ? t('filterForm:companyStructure.structureHolder') : t('filterForm:companyStructure.placeholder')
                          }
                          style={{ width: isMobile ? '100%' : "100%" }}
                          onlyShowActive={false}
                        />
                      )}
                    </Form.Item>
                    <Form.Item
                      style={{marginBottom: '0px'}}
                    >
                      {getFieldDecorator('filter.includeSubLevelCompanies', {
                        valuePropName: 'checked',
                        initialValue: true
                      })(
                        <Checkbox
                          onChange={this.handleSubLevelInputChange}
                        >
                          {t('filterForm:subLevelCompanies.label')}
                        </Checkbox>
                      )}
                    </Form.Item>
                </Col>

                {!structureFilter && (
                  <Col md={12} xs={24}>
                    <Form.Item
                      label={t('filterForm:name.label')}
                      style={{marginBottom: '0px'}}
                    >
                      {getFieldDecorator('filter.name', { initialValue: ''})(
                        <Input
                          allowClear
                          onChange={this.handleNameInputChange}
                        />
                      )}
                    </Form.Item>
                  </Col>
                )}

                {structureFilter && (
                  <Col md={12} xs={24}>
                    <Form.Item
                      label={t('filterForm:siteName.label')}
                      style={{marginBottom: '0px'}}
                    >
                      {getFieldDecorator('filter.siteName', { initialValue: ''})(
                        <Input
                          allowClear
                          onChange={this.handleSiteNameInputChange}
                        />
                      )}
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <Row>
                {structureFilter && (
                  <Col md={12} xs={24}>
                    <Form.Item
                      label={t('filterForm:depthLevel.label')}
                      style={{marginBottom: '0px'}}
                    >
                      {getFieldDecorator('filter.depthLevel', {
                        initialValue: 5
                      })(
                        this.buildDepthLevelSelector(0, 5)
                      )}
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </Form>
          </Card>
        )}
      </Media>
    );
  }
}

PhysicalLocationFilter.defaultProps = {
  currentFilter: {},
  renderButtons: null,
};

PhysicalLocationFilter.propTypes = {
  t: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  currentFilter: PropTypes.object,
  renderButtons: PropTypes.func,
  structureFilter: PropTypes.bool
};

export default Form.create()(withTranslation(['physicalLocations'])(PhysicalLocationFilter));
