'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _antd = require('antd');

var _styleModule = require('./style.module.scss');

var _styleModule2 = _interopRequireDefault(_styleModule);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Exception = function (_React$PureComponent) {
  _inherits(Exception, _React$PureComponent);

  function Exception() {
    _classCallCheck(this, Exception);

    return _possibleConstructorReturn(this, (Exception.__proto__ || Object.getPrototypeOf(Exception)).apply(this, arguments));
  }

  _createClass(Exception, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          status = _props.status,
          message = _props.message,
          children = _props.children;


      return _react2.default.createElement(
        'div',
        { className: _styleModule2.default.root },
        _react2.default.createElement(
          _antd.Row,
          null,
          _react2.default.createElement(_antd.Col, { span: 2 }),
          _react2.default.createElement(
            _antd.Col,
            { span: 16 },
            _react2.default.createElement(
              _antd.Card,
              { className: _styleModule2.default.card },
              _react2.default.createElement(
                'h1',
                { className: _styleModule2.default.status },
                status
              ),
              _react2.default.createElement(_antd.Divider, null),
              _react2.default.createElement(
                'p',
                { className: _styleModule2.default.message },
                message
              ),
              children && _react2.default.createElement(
                'p',
                { className: _styleModule2.default.footer },
                children
              )
            )
          ),
          _react2.default.createElement(_antd.Col, { span: 2 })
        )
      );
    }
  }]);

  return Exception;
}(_react2.default.PureComponent);

Exception.defaultProps = {
  children: null
};

Exception.propTypes = {
  status: _propTypes2.default.number.isRequired,
  message: _propTypes2.default.string.isRequired,
  children: _propTypes2.default.any
};

exports.default = Exception;