import { connect } from 'react-redux';

import PhysicalLocationStructure from '../../Components/PhysicalLocationStructure';
import { getPhysicalLocationStructure } from '../../reducers/physicalLocation';
import WithDefaults from '../WithDefaults';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  getPhysicalLocationStructure(...args) {
    return getPhysicalLocationStructure(dispatch, ...args);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithDefaults(PhysicalLocationStructure));
