import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { BatchProcess, UploadStep } from 'componentlibrary';
import Base from '../Base';
import BatchPhysicalLocationValidationStep from './BatchPhysicalLocationValidationStep';
import BatchPhysicalLocationSubmissionStep from './BatchPhysicalLocationSubmissionStep';

export class BatchPhysicalLocation extends Base {
  constructor(props) {
    super(props);

    this.uploadValidateAndConvert = this.uploadValidateAndConvert.bind(this);
    this.submitBatchPayload = this.submitBatchPayload.bind(this);
    this.getValidationStatus = this.getValidationStatus.bind(this);
    this.getSubmissionStatus = this.getSubmissionStatus.bind(this);
  }

  uploadValidateAndConvert(file) {
    const { batchXlsxUpload } = this.props;
    return this.dispatchWithAuth(batchXlsxUpload, file);
  }

  submitBatchPayload(payload) {
    const { submitBatch } = this.props;
    return this.dispatchWithAuth(submitBatch, payload);
  }

  getValidationStatus(transactionId) {
    const { getValidationStatus } = this.props;
    return this.dispatchWithAuth(getValidationStatus, transactionId);
  }

  getSubmissionStatus(transactionId) {
    const { getSubmissionStatus } = this.props;
    return this.dispatchWithAuth(getSubmissionStatus, transactionId);
  }

  render() {
    return (
      <BatchProcess>
        <UploadStep
          uploadFileHandler={this.uploadValidateAndConvert}
        />
        <BatchPhysicalLocationValidationStep
          getValidationStatus={this.getValidationStatus}
        />
        <BatchPhysicalLocationSubmissionStep
          submitBatchPayload={this.submitBatchPayload}
          getSubmissionStatus={this.getSubmissionStatus}
        />
      </BatchProcess>
    );
  }
}

BatchPhysicalLocation.propTypes = {
  t: PropTypes.func.isRequired,
  batchXlsxUpload: PropTypes.func.isRequired,
  getValidationStatus: PropTypes.func.isRequired,
  submitBatch: PropTypes.func.isRequired,
  getSubmissionStatus: PropTypes.func.isRequired,
  getIdToken: PropTypes.func.isRequired
};

export default withTranslation(['batchProcess'])(BatchPhysicalLocation);
