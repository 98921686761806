import { connect } from 'react-redux';

import PhysicalLocationTransactionView from '../../Components/PhysicalLocationTransactionView';
import {
  getBatchStatus,
  getXlsxLogForBatchTransaction
} from '../../reducers/physicalLocation';

import WithDefaults from '../WithDefaults';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  getBatchSubmissionStatus(...args) {
    return getBatchStatus(dispatch, 'batchUpsert', ...args);
  },
  getXlsxLogForBatchTransaction(...args) {
    return getXlsxLogForBatchTransaction(dispatch, ...args);
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithDefaults(PhysicalLocationTransactionView));
