import { combineReducers } from 'redux';
import appConfig from './appConfig';
import auth from './auth';
import physicalLocation from './physicalLocation';
import company from './company';

const appReducer = combineReducers({
  appConfig,
  auth,
  physicalLocation,
  company
});

export default (state, action) => appReducer(state, action);
