'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ValidationStep = exports.validationLoadingSteps = exports.validationErrorStep = exports.validationStepMapping = exports.VALIDATION_UNEXPECTED_ERROR = exports.VALIDATION_FAIL = exports.VALIDATION_PASS = exports.VALIDATION_VALIDATING_CELL_VALUE = exports.VALIDATION_FILE_FORMAT_FAIL = exports.VALIDATION_VALIDATING_FILE_FORMAT = exports.VALIDATION_INIT_FAILED = exports.VALIDATION_INITIATED = exports.VALIDATION_INITIALSING = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactI18next = require('react-i18next');

var _antd = require('antd');

var _ = require('.');

var _RestartButton = require('./RestartButton');

var _RestartButton2 = _interopRequireDefault(_RestartButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var VALIDATION_INITIALSING = exports.VALIDATION_INITIALSING = 'VALIDATION_INITIALSING';
var VALIDATION_INITIATED = exports.VALIDATION_INITIATED = 'VALIDATION_INITIATED';
var VALIDATION_INIT_FAILED = exports.VALIDATION_INIT_FAILED = 'VALIDATION_INIT_FAILED';

var VALIDATION_VALIDATING_FILE_FORMAT = exports.VALIDATION_VALIDATING_FILE_FORMAT = 'VALIDATING_FILE_FORMAT';
var VALIDATION_FILE_FORMAT_FAIL = exports.VALIDATION_FILE_FORMAT_FAIL = 'FILE_FORMAT_FAIL';

var VALIDATION_VALIDATING_CELL_VALUE = exports.VALIDATION_VALIDATING_CELL_VALUE = 'VALIDATING_CELL_VALUE';

var VALIDATION_PASS = exports.VALIDATION_PASS = 'VALIDATION_PASS';
var VALIDATION_FAIL = exports.VALIDATION_FAIL = 'VALIDATION_FAIL';

var VALIDATION_UNEXPECTED_ERROR = exports.VALIDATION_UNEXPECTED_ERROR = 'VALIDATION_UNEXPECTED_ERROR';

var validationStepMapping = exports.validationStepMapping = {};
validationStepMapping[VALIDATION_INITIALSING] = 0;
validationStepMapping[VALIDATION_INITIATED] = 0;
validationStepMapping[VALIDATION_INIT_FAILED] = 0;

validationStepMapping[VALIDATION_VALIDATING_FILE_FORMAT] = 1;
validationStepMapping[VALIDATION_FILE_FORMAT_FAIL] = 1;

validationStepMapping[VALIDATION_VALIDATING_CELL_VALUE] = 2;
validationStepMapping[VALIDATION_PASS] = 2;
validationStepMapping[VALIDATION_FAIL] = 2;

var validationErrorStep = exports.validationErrorStep = [VALIDATION_INIT_FAILED, VALIDATION_FILE_FORMAT_FAIL, VALIDATION_FAIL];

var validationLoadingSteps = exports.validationLoadingSteps = [VALIDATION_INITIALSING, VALIDATION_VALIDATING_FILE_FORMAT, VALIDATION_VALIDATING_CELL_VALUE];

var Step = _antd.Steps.Step;

var ValidationStep = exports.ValidationStep = function (_React$PureComponent) {
  _inherits(ValidationStep, _React$PureComponent);

  function ValidationStep(props) {
    _classCallCheck(this, ValidationStep);

    var _this = _possibleConstructorReturn(this, (ValidationStep.__proto__ || Object.getPrototypeOf(ValidationStep)).call(this, props));

    _this.confirmSubmission = _this.confirmSubmission.bind(_this);
    return _this;
  }

  _createClass(ValidationStep, [{
    key: 'confirmSubmission',
    value: function confirmSubmission() {
      var _props = this.props,
          t = _props.t,
          onSubmission = _props.onSubmission;

      _antd.Modal.confirm({
        width: 530,
        title: t('submitConfirmTitle'),
        content: t('submitConfirmation'),
        onOk: onSubmission,
        okText: t('common:yes'),
        cancelText: t('common:cancel')
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var _props2 = this.props,
          t = _props2.t,
          handleStepChange = _props2.handleStepChange,
          batchStep = _props2.batchStep,
          validationState = _props2.validationState,
          validationDetail = _props2.validationDetail,
          unexpectedError = _props2.unexpectedError;


      if (batchStep !== _.VALIDATION_STEP) {
        return null;
      }

      var isError = false;
      if (unexpectedError || validationErrorStep.includes(validationState)) {
        isError = true;
      }

      var currentStepIndex = validationStepMapping[validationState];

      var stepStatus = 'process';
      var isLoading = false;

      if (!isError) {
        isLoading = validationLoadingSteps.includes(validationState);
      } else {
        stepStatus = 'error';
      }

      var validationIsFinish = false;

      if (!isError && !isLoading) {
        if (validationState === VALIDATION_PASS) {
          validationIsFinish = true;
          stepStatus = 'finish';
        }
      }

      return React.createElement(
        React.Fragment,
        null,
        React.createElement(
          React.Fragment,
          null,
          React.createElement(
            _antd.Steps,
            { direction: 'vertical', current: currentStepIndex, status: stepStatus },
            React.createElement(Step, {
              title: t('batchProcess:validationStepInitialisingTitle'),
              description: t('batchProcess:validationStepInitialisingDescription'),
              icon: isLoading && currentStepIndex === 0 ? React.createElement(_antd.Icon, { type: 'sync', spin: true }) : ''
            }),
            React.createElement(Step, {
              title: t('batchProcess:validationStepFileFormatTitle'),
              description: t('batchProcess:validationStepFileFormatDescription'),
              icon: isLoading && currentStepIndex === 1 ? React.createElement(_antd.Icon, { type: 'sync', spin: true }) : ''
            }),
            React.createElement(Step, {
              title: t('batchProcess:validationStepDataTitle'),
              description: t('batchProcess:validationStepDataDescription'),
              icon: isLoading && currentStepIndex === 2 ? React.createElement(_antd.Icon, { type: 'sync', spin: true }) : ''
            })
          ),
          isError && validationState === VALIDATION_FILE_FORMAT_FAIL && React.createElement(_antd.Alert, {
            message: t('common:error'),
            description: t(validationDetail),
            type: 'error',
            showIcon: true
          }),
          isError && validationState === VALIDATION_FAIL && React.createElement(
            _antd.Button,
            {
              href: validationDetail
            },
            t('batchProcess:downloadValidationResult')
          ),
          validationIsFinish && React.createElement(
            React.Fragment,
            null,
            React.createElement(_antd.Divider, { style: { margin: '10px 0 10px' } }),
            React.createElement(
              _antd.Button,
              { type: 'primary', onClick: this.confirmSubmission, style: { marginRight: '10px' } },
              t('batchProcess:start')
            ),
            React.createElement(_RestartButton2.default, {
              showDivider: false,
              buttonType: 'default',
              handleStepChange: handleStepChange
            })
          )
        ),
        isError && React.createElement(_RestartButton2.default, {
          handleStepChange: handleStepChange
        })
      );
    }
  }]);

  return ValidationStep;
}(React.PureComponent);

ValidationStep.propTypes = {
  t: _propTypes2.default.func.isRequired,
  batchStep: _propTypes2.default.any.isRequired,
  validationState: _propTypes2.default.string,
  validationDetail: _propTypes2.default.any,
  handleStepChange: _propTypes2.default.func.isRequired,
  unexpectedError: _propTypes2.default.bool,
  onSubmission: _propTypes2.default.func.isRequired
};

ValidationStep.defaultProps = {
  validationState: null,
  unexpectedError: false,
  validationDetail: {}
};

exports.default = (0, _reactI18next.withTranslation)(['batchProcess'])(ValidationStep);