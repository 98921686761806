import { connect } from 'react-redux';

import PhysicalLocationExporter from '../../Components/PhysicalLocationExporter';
import { exportInit, getExportTransactionStatus } from '../../reducers/physicalLocation';
import WithDefaults from '../WithDefaults';

const mapDispatchToProps = (dispatch) => ({
  exportInit(...args) {
    return exportInit(dispatch, ...args);
  },
  getExportTransactionStatus(...args) {
    return getExportTransactionStatus(dispatch, ...args);
  }
});

export default connect(mapDispatchToProps)(WithDefaults(PhysicalLocationExporter));
