'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SubmissionStep = exports.submissionLoadingSteps = exports.submissionErrorStep = exports.submissionStepMapping = exports.SUBMISSION_UNEXPECTED_ERROR = exports.SUBMISSION_SUBMITTED = exports.SUBMISSION_SUBMITTING = exports.SUBMISSION_INIT_FAILED = exports.SUBMISSION_INITIATED = exports.SUBMISSION_INITIALSING = undefined;

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactI18next = require('react-i18next');

var _antd = require('antd');

var _reactRouterDom = require('react-router-dom');

var _ = require('.');

var _RestartButton = require('./RestartButton');

var _RestartButton2 = _interopRequireDefault(_RestartButton);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SUBMISSION_INITIALSING = exports.SUBMISSION_INITIALSING = 'SUBMISSIONN_INITIALSING';
var SUBMISSION_INITIATED = exports.SUBMISSION_INITIATED = 'SUBMISSION_INITIATED';
var SUBMISSION_INIT_FAILED = exports.SUBMISSION_INIT_FAILED = 'SUBMISSION_INIT_FAILED';

var SUBMISSION_SUBMITTING = exports.SUBMISSION_SUBMITTING = 'SUBMISSIONN_SUBMITTING';
var SUBMISSION_SUBMITTED = exports.SUBMISSION_SUBMITTED = 'SUBMISSION_SUBMITTED';
var SUBMISSION_UNEXPECTED_ERROR = exports.SUBMISSION_UNEXPECTED_ERROR = 'SUBMISSION_UNEXPECTED_ERROR';

var submissionStepMapping = exports.submissionStepMapping = {};
submissionStepMapping[SUBMISSION_INITIALSING] = 0;
submissionStepMapping[SUBMISSION_INITIATED] = 0;
submissionStepMapping[SUBMISSION_INIT_FAILED] = 0;

submissionStepMapping[SUBMISSION_SUBMITTING] = 1;
submissionStepMapping[SUBMISSION_SUBMITTED] = 1;
submissionStepMapping[SUBMISSION_UNEXPECTED_ERROR] = 1;

var submissionErrorStep = exports.submissionErrorStep = [SUBMISSION_INIT_FAILED, SUBMISSION_UNEXPECTED_ERROR];

var submissionLoadingSteps = exports.submissionLoadingSteps = [SUBMISSION_INITIALSING, SUBMISSION_SUBMITTING];

var Step = _antd.Steps.Step;

var SubmissionStep = exports.SubmissionStep = function (_React$PureComponent) {
  _inherits(SubmissionStep, _React$PureComponent);

  function SubmissionStep() {
    _classCallCheck(this, SubmissionStep);

    var _this = _possibleConstructorReturn(this, (SubmissionStep.__proto__ || Object.getPrototypeOf(SubmissionStep)).call(this));

    _this.submissionResultView = _this.submissionResultView.bind(_this);
    return _this;
  }

  _createClass(SubmissionStep, [{
    key: 'submissionResultView',
    value: function submissionResultView() {
      var _props = this.props,
          t = _props.t,
          submissionState = _props.submissionState,
          submissionDetail = _props.submissionDetail;


      if (![SUBMISSION_SUBMITTED, SUBMISSION_UNEXPECTED_ERROR].includes(submissionState)) {
        return null;
      }

      var unexpectedError = SUBMISSION_UNEXPECTED_ERROR === submissionState;

      var successRecordCount = null;
      var failRecordCount = null;
      var transactionViewLink = null;

      if (submissionDetail) {
        successRecordCount = submissionDetail.success;
        failRecordCount = submissionDetail.fail;
        transactionViewLink = submissionDetail.transactionViewLink;
      }

      return React.createElement(
        React.Fragment,
        null,
        React.createElement(_antd.Divider, { style: { margin: '10px 0 10px' } }),
        React.createElement(
          _antd.Row,
          { gutter: [16, 16] },
          unexpectedError && React.createElement(
            _antd.Col,
            { xs: 24, md: 24 },
            React.createElement(_antd.Alert, {
              message: t('common:error'),
              description: t('batchProcess:unexpectedErrorSubmitting'),
              type: 'error',
              showIcon: true
            })
          ),
          successRecordCount && successRecordCount > 0 ? React.createElement(
            _antd.Col,
            { xs: 24, md: 12 },
            React.createElement(_antd.Alert, {
              message: t('batchProcess:submissionSuccessfulMessage'),
              description: t('batchProcess:submissionSuccessfulDescription', { record_count: successRecordCount }),
              type: 'success',
              showIcon: true
            })
          ) : null,
          failRecordCount && failRecordCount > 0 ? React.createElement(
            _antd.Col,
            { xs: 24, md: 12 },
            React.createElement(_antd.Alert, {
              message: t('batchProcess:submissionFailedMessage'),
              description: t('batchProcess:submissionFailedDescription', { record_count: failRecordCount }),
              type: 'error',
              showIcon: true
            })
          ) : null
        ),
        transactionViewLink && React.createElement(
          _antd.Row,
          null,
          React.createElement(
            _reactRouterDom.Link,
            { to: transactionViewLink },
            React.createElement(
              _antd.Button,
              {
                type: 'primary',
                style: { marginTop: '10px' }
              },
              t('batchProcess:goToTransaction')
            )
          )
        )
      );
    }
  }, {
    key: 'render',
    value: function render() {
      var _props2 = this.props,
          t = _props2.t,
          handleStepChange = _props2.handleStepChange,
          batchStep = _props2.batchStep,
          submissionState = _props2.submissionState,
          unexpectedError = _props2.unexpectedError,
          submissionResultViewOverride = _props2.submissionResultViewOverride;


      if (batchStep !== _.SUBMISSION_STEP) {
        return null;
      }

      var isError = false;
      if (unexpectedError || submissionErrorStep.includes(submissionState)) {
        isError = true;
      }

      var currentStepIndex = submissionStepMapping[submissionState];

      var stepStatus = 'process';
      var isLoading = false;

      if (!isError) {
        isLoading = submissionLoadingSteps.includes(submissionState);
      } else {
        stepStatus = 'error';
      }

      if (!isError && !isLoading) {
        if (submissionState === SUBMISSION_SUBMITTED) {
          stepStatus = 'finish';
        }
      }

      return React.createElement(
        React.Fragment,
        null,
        React.createElement(
          _antd.Steps,
          { direction: 'vertical', current: currentStepIndex, status: stepStatus },
          React.createElement(Step, {
            title: t('batchProcess:submissionStepInitialisingTitle'),
            description: t('batchProcess:submissionStepInitialisingDescription'),
            icon: isLoading && currentStepIndex === 0 ? React.createElement(_antd.Icon, { type: 'sync', spin: true }) : ''
          }),
          React.createElement(Step, {
            title: t('batchProcess:submissionStepProcessingTitle'),
            description: t('batchProcess:submissionStepProcessingDescription'),
            icon: isLoading && currentStepIndex === 1 ? React.createElement(_antd.Icon, { type: 'sync', spin: true }) : ''
          })
        ),
        submissionResultViewOverride ? submissionResultViewOverride() : this.submissionResultView(),
        isError && React.createElement(_RestartButton2.default, {
          handleStepChange: handleStepChange
        })
      );
    }
  }]);

  return SubmissionStep;
}(React.PureComponent);

SubmissionStep.propTypes = {
  t: _propTypes2.default.func.isRequired,
  batchStep: _propTypes2.default.any.isRequired,
  submissionState: _propTypes2.default.any,
  submissionDetail: _propTypes2.default.any.isRequired,
  handleStepChange: _propTypes2.default.func.isRequired,
  unexpectedError: _propTypes2.default.bool,
  submissionResultViewOverride: _propTypes2.default.func
};

SubmissionStep.defaultProps = {
  submissionState: null,
  unexpectedError: false,
  submissionResultViewOverride: null
};

exports.default = (0, _reactI18next.withTranslation)(['batchProcess'])(SubmissionStep);